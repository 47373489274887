<template>
  <div class="nav-container">
    <div class="nav d-flex align-items-center justify-content-between">
      <div>
        <img class="logo" src="@/assets/index/logo.png" alt="" srcset="">
      </div>
      <div>
        <div class="d-flex">
          <b-navbar toggleable="lg">
            <div class="container">
                <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
                <b-collapse id="nav-collapse" is-nav>
                  <b-nav-item :class="{'router-link-exact-active':activeName === 'Index'}" href="#/">首页</b-nav-item>
                  <b-nav-item :class="{'router-link-exact-active':activeName === 'Product'}" href="#/product">产品服务</b-nav-item>
                  <b-nav-item :class="{'router-link-exact-active':activeName === 'News'}" href="#/news">新闻资讯</b-nav-item>
                  <b-nav-item :class="{'router-link-exact-active':activeName === 'Free'}" href="#/free">免费试用</b-nav-item>
                  <b-nav-item :class="{'router-link-exact-active':activeName === 'Member'}" href="#/member">付费会员</b-nav-item>
                </b-collapse>
              </div>
          </b-navbar>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      activeName:''
    }
  },
  watch: {
    $route: {
      immediate: true,
      deep: true,
      handler(newValue) {
        this.activeName = newValue.name
      }
    }
  },
  mounted () {
  },
}
</script>
<style lang="less" scoped>
.nav{
  padding:0 30px;
  height:80px;
  box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.0600);
  .logo{
    width:124px;
    height:66px;
  }
  .nav-item{
    margin-right:20px;
  }
  .nav-item{

    font-size:16px;
    padding: 10px;
    a{
      color:#222;
    }
    &.router-link-exact-active a{
      color:@primary-color;
      border-bottom: 3px solid @primary-color;
    }
  }
}

.navbar-light .navbar-toggler{
  border:none;
}

 @media screen and (max-width: 991px) {
    .navbar-toggler:focus{
      box-shadow:none;
    }
    .navbar-toggler{
      position: absolute;
      top: -10px;
      right: 0;
    }

    .navbar-collapse{
      width: 100vw;
      background: #fff;
      position: absolute;
      top: 48px;
      right:-30px;
      z-index:999;
      box-shadow: 0 0.125rem 0.25rem rgb(0 0 0 / 8%) !important;
    }
  }

</style>