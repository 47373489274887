<template>
  <div class="footer container">
    <div class="row d-flex align-items-center justify-content-end">
      <div class="col-xl-6 col-sm-12">
        <p>深圳齐进步科技有限公司</p>
        <p>电话：400-660-6882  邮箱：fw@qijinbu.cn</p>
        <p>© 2009-2022 深圳市齐进步科技有限公司 版权所有 <a href="https://beian.miit.gov.cn/" target="_blank">粤ICP备2022055881号-1</a></p>
      </div>
      <div class="col-xl-6 col-sm-12">
        <div class="d-flex flex-wrap">
          <router-link v-for="(nav,i) in menu" :key="i" :to="nav.path">{{nav.meta.title}}<span v-if="i < menu.length - 1">|</span></router-link>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import routes from '@/router/routes'
export default {
  data() {
    return {
      menu: routes[1].children.filter(item => item.meta.isMenu)
    }
  },
  mounted () {
  },
}
</script>
<style lang="less" scoped>
  .footer{
    padding:20px 0;
    font-size: 14px;
    color: #222222;
    p{
      margin-bottom:10px;
    }
    a{
      color: #222222;
      text-decoration:none;
      span{
        padding:0 15px;
      }
    }
  }

  @media screen and (max-width: 991px) {
    .footer{
      padding:20px;
      a{
        min-width:7em;
        margin-bottom: 5px;
      }
    }
  }
</style>
