<template>
  <div class="try-content text-center">
    <h2>马上免费试用！</h2>
    <div>
      <p>联系我们</p>
      <h3>400-660-6882</h3>
    </div>
    <div>
      <p>或提交以下信息，我们将第一时间联系您试用</p>
      <a-form-model class="try-form" layout="inline" :model="formInline"  ref="ruleForm" :rules="rules" @submit="handleSubmit" @submit.native.prevent>
        <a-form-model-item prop="school">
          <a-input class="try-form-input" v-model="formInline.school" placeholder="请输入您所在的学校"></a-input>
        </a-form-model-item>
        <a-form-model-item prop="tel">
          <a-input class="try-form-input" v-model="formInline.tel" placeholder="请输入您的联系电话"></a-input>
        </a-form-model-item>
        <a-form-model-item>
          <a-button class="try-form-btn" :loading="loading" type="primary" html-type="submit"> 立即提交 </a-button>
        </a-form-model-item>
      </a-form-model>
    </div>

  </div>
</template>
<script>
import { trialApply } from "@/services/news";
export default {
  data() {
    return {
      loading:false,
      formInline: {
        school: '',
        tel: '',
      },
      rules: {
        school: [
          { required: true, message: '学校不能为空', trigger: 'blur' }
        ],
        tel: [
          { required: true, message: '电话不能为空', trigger: 'blur' }
        ],
      }
    };
  },
  methods: {
    handleSubmit() {
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          this.loading = true
          trialApply({
            ...this.formInline
          }).then(res =>{
            this.loading = false
            const { code, msg } = res.data;
            if (code === 200) {
              this.$message.success('提交成功！');
            }else{
              this.$message.error(msg);
            }
          },()=>{
            this.loading = false
          })

        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
  .try-content{
    width:100%;
    background:url(../assets/index/index_06_bg@2x.png) no-repeat;
    background-size:100% 100%;
    padding:70px 0;
    color:#a0b3d2;
    font-size:16px;
    h2{
      color: #FFFFFF;
      font-size: 45px;
      margin-bottom:60px;
    }
    p{
      margin-bottom:5px;
    }
    h3{
      color:#FFC000;
      font-size:40px;
      margin-bottom:55px;
    }
    .try-form{
      margin-top:20px;
    }
    .try-form-input{
      width:300px;
      height:40px;
      background: rgba(255,255,255,0.2);
    }
    .try-form-btn{
      width: 180px;
      height: 40px;
      background: linear-gradient(90deg, #34ADFF 0%, #1A7AF8 100%);
      border-radius: 4px;
      border:none;
    }

  }

  @media screen and (max-width: 991px) {
    .ant-form-inline .ant-form-item{
      margin-bottom:20px;
    }
  }

</style>
