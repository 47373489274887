<template>
  <div class="layout-container">
    <Nav></Nav>
    <router-view />
    <Try></Try>
    <Footer></Footer>
  </div>
</template>

<script>
import Nav from './nav.vue'
import Try from './try.vue'
import Footer from './footer.vue'
export default {
  components: {
    Nav,
    Try,
    Footer
  },
}
</script>